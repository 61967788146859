import * as React from 'react'

import { makeStyles } from '@material-ui/styles'
import {
  StyledEngineProvider, useTheme,
  // makeStyles
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';

import Viewer, { Worker, defaultLayout, RenderViewerProps, ScrollMode, SelectionMode, SpecialZoomLevel, PageChangeEvent } from '@phuocng/react-pdf-viewer';
import '@phuocng/react-pdf-viewer/cjs/react-pdf-viewer.css';

const useStyles = makeStyles((theme) => ({
  viewerContainer: {
    backgroundColor: '#eeeeee',
    // overflow: 'auto',
    fontSize: theme.typography.fontSize,
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vh - 96px)',
    },
    [theme.breakpoints.up('lg')]: {
      height: 'calc(100vh - 224px)',
    }
  }
}))

function useWidth() {
  const theme = useTheme();
  const keys = [...theme.breakpoints.keys].reverse();
  return (
    keys.reduce((output, key) => {
      const matches = useMediaQuery(theme.breakpoints.up(key));
      return !output && matches ? key : output;
    }, null) || 'xs'
  );
}

const renderToolbar = (toolbarSlot: ToolbarSlot): React.ReactElement => {

  return (
      <div
          style={{
              alignItems: 'center',
              display: 'flex',
              width: '100%',
          }}
      >
          <div
              style={{
                  alignItems: 'center',
                  display: 'flex',
                  flexGrow: 1,
                  flexShrink: 1,
                  justifyContent: 'space-between',
              }}
      >
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: 'flex-start',
          }}
        >
          {/* <div style={{ padding: '0 2px' }}>
            {toolbarSlot.wrappedScrollingButton}
          </div> */}
          <Box sx={{ display: { xs: 'none', md: 'block' } }}>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.previousPageButton}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.currentPage + 1} / {toolbarSlot.numPages}
            </div>
            <div style={{ padding: '0 2px' }}>
              {toolbarSlot.nextPageButton}
            </div>
          </Box>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.zoomOutButton}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.zoomPopover}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.zoomInButton}
          </div>
        </div>
        <div
          style={{
            alignItems: 'center',
            display: 'flex',
            flexGrow: 1,
            flexShrink: 1,
            justifyContent: 'flex-end',
          }}
        >
          {/* <div style={{ padding: '0 2px' }}>
            {toolbarSlot.printButton}
          </div> */}
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.downloadButton}
          </div>
          <div style={{ padding: '0 2px' }} sx={{ display: { xs: 'none', md: 'block' } }}>
            {toolbarSlot.searchPopover}
          </div>
          <div style={{ padding: '0 2px' }}>
            {toolbarSlot.fullScreenButton}
          </div>
          <div style={{ padding: '0 2px' }} sx={{ display: { xs: 'none', md: 'block' } }}>
            {toolbarSlot.moreActionsPopover}
          </div>
              
        </div>
      </div>
    </div>
  );
};

const layout = (
  isSidebarOpened: boolean,
  container: Slot,
  main: Slot,
  toolbar: RenderToolbar,
  sidebar: Slot,
): React.ReactElement => {
    return defaultLayout(
      isSidebarOpened,
      container,
      main,
      toolbar(renderToolbar),
      sidebar,
    );
};

const handlePageChange = (e: PageChangeEvent) => {
    localStorage.setItem('current-page', `${e.currentPage}`);
};

const initialPage = localStorage.getItem('current-page')
    ? parseInt(localStorage.getItem('current-page'), 10)
    : 0;

const PDFViewer = ({ url }) => {
  const classes = useStyles();
  const width = useWidth();

  const scale = {
    xs: false,
    sm: false,
    md: false,
    lg: 0.65,
    xl: 0.65,
  }
  const defaultScale = scale[width] || SpecialZoomLevel.PageFit

  // console.log("PDFViewer", defaultScale, url)
  
  return (
    <StyledEngineProvider injectFirst>
      <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.6.347/build/pdf.worker.min.js">
        <div className={classes.viewerContainer}>
          <Viewer
            // onDocumentLoad={}
            fileUrl={url}
            // render={render}
            layout={layout}
            onPageChange={handlePageChange}
            initialPage={initialPage}
            selectionMode={SelectionMode.Hand}
            // scrollMode={ScrollMode.Wrapped}
            // defaultScale={defaultScale}
          />
        </div>
      </Worker>
    </StyledEngineProvider>
  );
};

export default PDFViewer