import React from 'react'
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom'

import { withGoogleSheets } from 'react-db-google-sheets'

import { makeStyles } from '@material-ui/styles'
import {
  StyledEngineProvider,
  // makeStyles,
  Box, Container
} from '@material-ui/core'

import MainNav from './comps/MainNav'
import Footer from './comps/Footer'

import layouts from './layouts'

import RouteScrollTop from './comps/RouteScrollTop'
import ShowBreakpoints from './tests/ShowBreakpoints'

const useStyles = makeStyles((theme) => ({
  mainContainer: {
    position: 'relative',
    color: 'rgba(0, 0, 0, 1)',
    marginBottom: theme.spacing(theme.shape.verticalSpacer * 4),

    [theme.breakpoints.up('md')]: {
      marginTop: theme.spacing(18),
    },
    [theme.breakpoints.down('md')]: {
      marginTop: theme.spacing(16),
      paddingRight: theme.spacing(1),
      paddingLeft: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(15),
    },
  },
}))

const App = ({ db }) => {
  const classes = useStyles()

  const configSource = db.config
  var data = {}
  for (var i = 0; i < configSource.length; i++) {
    data[configSource[i].item] = configSource[i].itemValue
  }

  const siteLanguage = data.siteDefaultLanguage

  // console.log(data)

  return (
    <BrowserRouter>
      <StyledEngineProvider  injectFirst>
        <MainNav dataSource={db.menu} archive={db.menu_arhiva} config={data} siteLanguage={siteLanguage} />

        <Box component="main" className={classes.mainContainer}>
          <ShowBreakpoints
            show={
              JSON.parse(data.testBreakpoints)
              // true
            }
          />
          <Switch>
            {db.menu.map((route, index) => {
              if (JSON.parse(route.addRoute)) {
                // console.log(route)
                const ComponentIterator = layouts[route.layout]
                // const routePath = '/' + siteLanguage + '/' + route.itemSlug
                const routePath = route.route
                return (
                  <Route
                    {...JSON.parse(route.routeProps)}
                    path={routePath}
                    key={index}
                  >
                    <Container maxWidth="lg">
                      <RouteScrollTop />
                      <ComponentIterator
                        config={data}
                        dataSource={db[route.contentSource]}
                        siteLanguage={siteLanguage}
                        pageName={route.itemTitle}
                        // setData={sliderData}
                        // program={programData}
                        // despre={despreData}
                      />
                    </Container>
                  </Route>
                )
              }
              return null
            })}
            <Redirect from="/teme" to="/pentru-elevi/teme" />
            <Redirect from="*" to="" />
          </Switch>
        </Box>
        <Footer showSiteName={true} config={data} />
      </StyledEngineProvider>
    </BrowserRouter>
  )
}

export default withGoogleSheets()(App)
