import {
  StyledEngineProvider,
  Card,
  // CardActionArea,
  CardContent,
  CardHeader,
  CardActions,
  // CardMedia,
  Chip,
  Typography,
  Box,
  // Link,
} from '@material-ui/core';
import {
  makeStyles,
} from '@material-ui/styles';

import moment from '../../helpers/moment';

const useStyles = makeStyles(theme => ({
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9,
    cursor: 'pointer'
  },
  listInline: {
    display: 'flex',
    flexDirection: 'row',
    padding: 0,
    flexWrap: 'wrap'
  },
  demo: {
    backgroundColor: theme.palette.background.paper,
  },

  title: {
    '& > a': {
      textDecoration: 'none!important',
      color: '#000!important',
    }
  },

  card: {
    width: '100%',
    borderRadius: theme.spacing(0.5),
    cursor: 'pointer',
    transition: theme.transitions.create('box-shadow'),


    '&:hover': {
      boxShadow: theme.shadows[12],
    },    

  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
  },
  cardActions: {
    paddingTop: 0,
  },

  categoryChip: {
    margin: theme.spacing(0.5, 0.5, 0, 0),
  },
  cardActionsChip: {
    margin: theme.spacing(0, 0.5, 1, 0),
  },
}))

const ActivityCard = ({ activity, route }) => {
  const classes = useStyles();

  const handleClickActivity = ev => {
    ev.preventDefault();
    window.open(`${route}${activity.slug}`, '_blank');
  };

  const Targets = () => {
    return (
      <>
        {activity.targets && activity.targets.length > 0 && (
          <Box display="flex" flexWrap="wrap">
            {activity.targets.map((target, key) => (
              <Chip
                size="small"
                // color="primary"
                label={target.name}
                key={key.toString()}
                // onClick={() => handleClickOnLabel(target.id, 'targets')}
                onClick={(ev) => handleClickActivity(ev)}
                // p={key > 0 || key !== activity.locations.length - 1 ? 0.8 : 0}
                // ml={key > 0 || key !== activity.locations.length - 1 ? 0.8 : 0}
                className={classes.cardActionsChip}
              />
            ))}
          </Box>
        )}
      </>
    );
  };

  return (
    <StyledEngineProvider injectFirst>
      <Card elevation={3} className={classes.card}>
        {/* <CardHeader component={Categories} style={{ paddingBottom: 0 }} /> */}
        <CardContent onClick={(ev) => handleClickActivity(ev)} className={classes.cardContent} >
          <Typography
            variant="h5"
            component="h2"
            color="primary"
            gutterBottom
          >
            {activity.title}
          </Typography>

          <Box mb={1}>
            {
              activity.startDate
              && <Typography
                variant="subtitle2"
                color="textSecondary"
                component="span"
                gutterBottom
              >
                {moment(activity.startDate).format(activity.endDate && activity.startDate !== activity.endDate ? 'DD MMM YYYY' : 'D MMMM YYYY')}
              </Typography>
            }
            {
              activity.endDate && activity.startDate !== activity.endDate
              && <Typography
                variant="subtitle2"
                color="textSecondary"
                component="span"
                gutterBottom
              >
                {' - '}{moment(activity.endDate).format('DD MMM YYYY')}
              </Typography>
            }
          </Box>

          {activity.heroImage && <img
            src={activity.heroImage}
            alt={activity.title}
          />}

          {/* {activity.day && <Typography
            variant="subtitle2"
            color="textSecondary"
            component="p"
            gutterBottom
          >
            {activity.day}
            {activity.start_time && activity.start_time}
          </Typography>} */}

          {/* {activity.description && <Typography
            variant="body2"
            color="textSecondary"
            component="p"
          >
            {activity.description}
          </Typography>} */}

        </CardContent>

        <CardActions disableSpacing className={classes.cardActions}>
          <Box
            // mb={1}
            px={1}
            className={classes.demo}
          >
            {/* {Locations()} */}
            {Targets()}
          </Box>
        </CardActions>

        {/* <CardMedia
          className={classes.media}
          // image={`${process.env.PUBLIC_URL}/${activity.heroImage}`}
          image={activity.heroImage}
          title={activity.title}
          onClick={(ev) => handleClickActivity(ev)}
        /> */}
      </Card>
    </StyledEngineProvider>
  )
}

export default ActivityCard