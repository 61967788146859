import React from 'react'
// import PropTypes from 'prop-types'

import ReactMarkdown from 'react-markdown'

import { makeStyles } from '@material-ui/styles'
import {
  StyledEngineProvider,
  // makeStyles,
  Typography
} from '@material-ui/core'

import components from '../comps/components'

const useStyles = makeStyles(theme => ({
  heroContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(theme.shape.verticalSpacer),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(-4),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(-4.5),
    },
  },
  heroImage: {
    maxWidth: '100%',
    margin: '0 auto',
    // borderRadius: theme.shape.borderRadius
  },
  titleContainer: {
    marginBottom: theme.spacing(theme.shape.verticalSpacer),
  },
}))

function BlogArticle({ config, dataSource, siteLanguage }) {
  const classes = useStyles()

  return (
    <StyledEngineProvider injectFirst>
      {dataSource &&
        dataSource.map((data, index) => {
          const RenderComponent = components[data.type]
          const getProps = {
            ...JSON.parse(data.props),
            // ...testProps
          }
          if (JSON.parse(data.display)) {
            // const domenii = data.keywords && data.keywords.split(',')
            return (
              <React.Fragment key={index}>
                {data.title &&
                  data.type !== 'Alert' &&
                  data.type !== 'Button' &&
                  data.type !== 'ButtonInternal' &&
                  data.type !== 'NavLink' &&
                  data.type !== 'Image' &&
                  data.type !== 'IframeHero' &&
                  data.type !== 'PageBanner' &&
                  data.type !== 'SciComm' && (
                    <Typography
                      variant="h4"
                      {...getProps}
                      className={classes.sectionTitle}
                    >
                      {data.title}
                    </Typography>
                  )}
                {data.type === 'SciComm' && (
                  <RenderComponent
                    componentProps={getProps}
                    // href={data.content}
                    title={data.title}
                    className={classes[`GSH${data.type}`]}
                  >
                    {data.title}
                  </RenderComponent>
                )}
                {data.content &&
                  data.type !== 'SciComm' &&
                  (data.type === 'Button' || data.type === 'ButtonInternal'
                    ? (data.type === 'ButtonInternal'
                      ? (
                        <RenderComponent
                          componentProps={getProps}
                          to={data.content}
                          title={data.title}
                          className={classes[`GSH${data.type}`]}
                        >
                          {data.title}
                        </RenderComponent>
                      ) : (
                        <RenderComponent
                          componentProps={getProps}
                          href={data.content}
                          title={data.title}
                          className={classes[`GSH${data.type}`]}
                        >
                          {data.title}
                        </RenderComponent>
                      ))
                   : (
                    <RenderComponent
                      {...getProps}
                      component={data.component}
                      title={data.title && data.title}
                      className={classes[`GSH${data.type}`]}
                    >
                      {data.type === 'Typography' || data.type === 'Box' ? (
                        <ReactMarkdown children={data.content} />
                      ) : (
                        data.content
                      )}
                    </RenderComponent>
                  ))}
              </React.Fragment>
            )
          }
          return null
        })}
    </StyledEngineProvider>
  )
}

BlogArticle.propTypes = {}

export default BlogArticle
