import React from 'react'
import PropTypes from 'prop-types'
// import moment from 'moment'
// import 'moment/locale/ro'
import ReactMarkdown from 'react-markdown'

import clsx from 'clsx'
import { makeStyles } from '@material-ui/styles'
import {
  StyledEngineProvider,
  // makeStyles,
  alpha,
  Grid,
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  // Button,
  Chip,
} from '@material-ui/core'
import OpenModalIcon from '@material-ui/icons/Launch'

import TopicDetails from '../comps/TopicDetails'
import StatusAvatar from '../comps/StatusAvatar'

// import "./styles.css";

const useStyles = makeStyles(theme => ({
  heroContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginBottom: theme.spacing(theme.shape.verticalSpacer),

    [theme.breakpoints.up('sm')]: {
      marginTop: theme.spacing(-4),
    },
    [theme.breakpoints.only('xs')]: {
      marginTop: theme.spacing(-4.5),
    },
  },
  heroImage: {
    maxWidth: '100%',
    margin: '0 auto',
    // borderRadius: theme.shape.borderRadius
  },

  domainTitle: {
    margin: theme.spacing(6, 0, 0),

    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },
  firstDomainTitle: {
    margin: theme.spacing(0),
  },

  titleContainer: {
    marginBottom: theme.spacing(theme.shape.verticalSpacer),
  },

  title: {
    fontSize: 14,
  },
  titleLabel: {
    marginRight: theme.spacing(0.5),
    lineHeight: `${theme.spacing(2.5)}`,
    verticalAlign: 'middle',
  },

  card: {
    position: 'relative',
    // marginBottom: theme.spacing(1),
    padding: 16,
    backgroundColor: 'rgba(255, 255, 255, 0.65)',
    // border: "1px solid #ccc",
    borderRadius: 0,

    [theme.breakpoints.down('sm')]: {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },

    '&.hasLink': {
      backgroundColor: 'rgba(255, 255, 255, 0.85)',

      [theme.breakpoints.down('sm')]: {
        backgroundColor: 'rgba(255, 255, 255, 1)',
      },
    },
    '&.hasLink:hover': {
      backgroundColor: 'white',
    },
    '&.hasLink,&.hasLink *': {
      cursor: 'pointer',
    },
    '& *': {
      cursor: 'default',
    },
    '& .MuiButton-root.Mui-disabled': {
      backgroundColor: 'Crimson',
      color: 'white',
      opacity: 0.65,
    },
  },
  cardTitle: {
    marginTop: 0,
    color: theme.palette.primary.main,
    '&>p': {
      margin: 0,
    },
  },
  cardImageContainer: {
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    overflow: 'hidden',
    height: 0,
    paddingTop: '100%',
    borderRadius: '50%',
  },
  cardImage: {
    position: 'absolute',
    top: 0,
    width: 'auto',
    height: '100%',
  },
  contentImage: {
    maxWidth: '100%',
    margin: theme.spacing(2, 0),
  },

  extraData: {
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
  },

  actionButton: {
    boxShadow: 'none',
  },

  section: {
    marginTop: theme.spacing(2),
    padding: theme.spacing(2, 2, 1.5),
    border: `1px solid ${alpha(theme.palette.secondary.light, 0.47)}`,
    borderRadius: theme.shape.borderRadius,
  },
  sectionListFirst: {
    padding: theme.spacing(1.5, 0),
    borderWidth: '1px 0',
    borderRadius: 0,
  },
  sectionList: {
    padding: theme.spacing(0, 0, 1.5, 0),
    borderWidth: '0 0 1px',
    borderRadius: 0,
  },
  sectionListTop: {
    padding: theme.spacing(1.5, 0, 0),
    borderWidth: '1px 0 0',
    borderRadius: 0,
  },

  chip: {
    margin: theme.spacing(0, 0.25, 0.5),
    fontWeight: 500,
    textTransform: 'uppercase',
  },
  chipDomenii: {
    margin: theme.spacing(0.25),
    backgroundColor: theme.palette.secondary.main,
    borderRadius: theme.shape.borderRadius,
    // color: theme.palette.secondary.contrastText,
    color: 'white',
    // textTransform: "capitalize",

    // '&:hover': {
    //   backgroundColor: theme.palette.secondary.dark
    // }
  },
}))

function BlogList({ config, dataSource }) {
  // console.log(props);
  const classes = useStyles()

  function groupBy(objectArray, property) {
    return objectArray.reduce((acc, obj) => {
      const key = obj[property]
      if (!acc[key]) {
        acc[key] = []
      }
      // Add object to list for given key's value
      acc[key].push(obj)
      return acc
    }, {})
  }

  const data = groupBy(dataSource, 'domain')
  console.log(config.showTotalRegistered)

  return (
    <StyledEngineProvider injectFirst>
      <Grid container spacing={6}>
        {data &&
          Object.keys(data).map((domain, dID) => {
            // console.log(data[item])
            return (
              <React.Fragment key={dID}>
                <Grid item xs={12}>
                  <Typography
                    variant="h3"
                    component="h1"
                    className={clsx(
                      classes.domainTitle,
                      dID === 0 && classes.firstDomainTitle,
                    )}
                  >
                    {domain}
                  </Typography>
                </Grid>
                {data[domain].map((item, index) => {
                  if (JSON.parse(item.display)) {
                    const domenii = item.keywords && item.keywords.split(',')
                    const showStatus = JSON.parse(config.showTotalRegistered)
                    const tooltipListData = [
                      {
                        title: 'Total înscrieri',
                        label: item.registered,
                      },
                    ]
                    return (
                      <Grid item xs={12} key={index}>
                        <Card
                          elevation={3}
                          className={clsx(classes.card, 'hasLink')}
                          // onClick={
                          //   item.FullyBooked !== '0' ? null : () => openYCBM(item.URL)
                          // }
                          key={index}
                        >
                          <CardContent>
                            <Grid container spacing={3}>
                              {item.icon && (
                                <Grid
                                  item
                                  xs={7}
                                  sm={4}
                                  md={3}
                                  style={{ margin: '0 auto' }}
                                >
                                  {showStatus && (
                                    <StatusAvatar
                                      tooltipPlacement="right-end"
                                      tooltipListData={tooltipListData}
                                      src={item.icon}
                                      title={item.title}
                                      total={item.registered}
                                    />
                                  )}
                                  {/* <Box className={classes.cardImageContainer}>
                                    <img
                                      alt=""
                                      src={item.icon}
                                      className={classes.cardImage}
                                    />
                                  </Box> */}
                                </Grid>
                              )}
                              <Grid
                                item
                                xs={12}
                                md={item.icon ? 9 : 12}
                                className={classes.extraData}
                              >
                                <Typography
                                  variant="h5"
                                  component="h2"
                                  gutterBottom
                                  className={classes.cardTitle}
                                >
                                  <ReactMarkdown children={item.title} />
                                </Typography>
                                <Typography
                                  variant="body2"
                                  component="div"
                                  gutterBottom
                                >
                                  <ReactMarkdown
                                    children={item.shortDescription}
                                  />
                                </Typography>
                                {item.keywords && (
                                  <Box
                                    className={clsx(
                                      classes.section,
                                      classes.sectionListFirst,
                                    )}
                                  >
                                    <Typography
                                      className={classes.title}
                                      color="textSecondary"
                                      component="div"
                                    >
                                      <Typography
                                        component="span"
                                        className={clsx(
                                          classes.title,
                                          classes.titleLabel,
                                        )}
                                      >
                                        Cuvinte cheie:
                                      </Typography>
                                      {domenii.map((item, index) => {
                                        return (
                                          <Chip
                                            size="small"
                                            label={item}
                                            key={index}
                                            className={classes.chipDomenii}
                                          />
                                        )
                                      })}
                                    </Typography>
                                  </Box>
                                )}
                              </Grid>
                            </Grid>
                          </CardContent>
                          <CardActions>
                            <Grid container spacing={3}>
                              <Grid
                                item
                                xs={12}
                                align="right"
                                className={classes.extraData}
                              >
                                {/* {item.Nivel &&
                                  nivel.map((item, index) => {
                                    return (
                                      <Chip
                                        color="secondary"
                                        size="small"
                                        label={item}
                                        key={index}
                                        className={classes.chip}
                                      />
                                    );
                                  })} */}
                                {/* <Button
                                  color="primary"
                                  variant="contained"
                                  size={'large'}
                                  disabled={false}
                                  endIcon={<OpenModalIcon />}
                                  className={classes.actionButton}
                                >
                                  Detalii
                                </Button> */}
                                <TopicDetails
                                  dialogData={item}
                                  buttonColor="primary"
                                  buttonVariant="contained"
                                  buttonSize="large"
                                  buttonText="Detalii"
                                  buttonEndIcon={<OpenModalIcon />}
                                  classes={classes}
                                />
                              </Grid>
                            </Grid>
                          </CardActions>
                        </Card>
                      </Grid>
                    )
                  }
                  return null
                })}
              </React.Fragment>
            )
          })}
      </Grid>
    </StyledEngineProvider>
  )
}

BlogList.propTypes = {
  db: PropTypes.shape({
    sheet1: PropTypes.arrayOf(PropTypes.object),
  }),
}

export default BlogList
